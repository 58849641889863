import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store'
import router from './router'
import { registerLicense } from '@syncfusion/ej2-base'
registerLicense('Mgo+DSMBaFt/QHNqVVhlWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5RdkxmWHtfc3FXRw==;Mgo+DSMBPh8sVXJ0S0V+XE9BcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUVhWXZbcXBUQmNcVg==;ORg4AjUWIQA/Gnt2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjW39XdXVRR2RYVEM=;MTI0MDU3OUAzMjMwMmUzMzJlMzBaT2NzU3l3RE1TNDNoc0NJb2F2WFkwaWNLUjdkcWZsNi9GTUZxZUlVcllNPQ==;MTI0MDU4MEAzMjMwMmUzMzJlMzBJOUs5WXljdXhIb0lwM0FIeDBvSDVlQ3gyeVI2TjZhY3d3QWl6TGZIRWRVPQ==;NRAiBiAaIQQuGjN/V0Z+X09EaFpEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWX1eeXFUQmFZU0B+;MTI0MDU4MkAzMjMwMmUzMzJlMzBWT1dTaEk4MnJINmFmNVpFOElwQkRScVE4eVplVExaRTZiVUFFdjZrNFI4PQ==;MTI0MDU4M0AzMjMwMmUzMzJlMzBjWUNKZE5zU2lPSU83V09sSzVvQzFIK2IyZ1l0bi9tbk5DNHZtVFNQekJzPQ==;Mgo+DSMBMAY9C3t2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjW39XdXVRR2ZcUEw=;MTI0MDU4NUAzMjMwMmUzMzJlMzBTazNuZ2I2ZFl3TWFaMVk1bmU2UDRNVEpIc0JhQXhKNVk5ZWhEaGd4QU9jPQ==;MTI0MDU4NkAzMjMwMmUzMzJlMzBYV3JpTHJGZzRENUlQNnEzanF5TnBoZDFIeEE3ZjYrVVNQWmI2c0hpQnZzPQ==;MTI0MDU4N0AzMjMwMmUzMzJlMzBWT1dTaEk4MnJINmFmNVpFOElwQkRScVE4eVplVExaRTZiVUFFdjZrNFI4PQ==')
// import "primevue/resources/themes/saga-blue/theme.css"
import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./App.scss";
// import "./less/main.less";
// import "./less/theme.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import SimpleVueValidator from 'simple-vue3-validator'
import '@fortawesome/fontawesome-pro/css/all.css'

// FONT AWESOME ICONS
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

library.add(fas, far, fad);
dom.watch();

// import "prismjs/themes/prism-coy.css";
// import "./assets/styles/demo/flag.css";

import ConfirmationService from "primevue/confirmationservice";
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AutoComplete from 'primevue/autocomplete';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chart from "primevue/chart";
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ColorPicker from 'primevue/colorpicker';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Menu from 'primevue/menu';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Ripple from 'primevue/ripple';
import PickList from 'primevue/picklist';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import SplitButton from 'primevue/splitbutton';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Password from 'primevue/password';
import Card from "primevue/card";
import MultiSelect from 'primevue/multiselect'
import Message from 'primevue/message';
import InlineMessage from "primevue/inlinemessage";
import { initI18n } from '@/i18n/index';
import Steps from "primevue/steps";
import TreeTable from "primevue/treetable";
import TreeSelect from "primevue/treeselect";
import OverlayPanel from 'primevue/overlaypanel';
import ConfirmDialog from "primevue/confirmdialog";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Chips from "primevue/chips";
import ColumnGroup from "primevue/columngroup";
import Menubar from "primevue/menubar";
import Row from "primevue/row";
import Editor from "primevue/editor";
import ToggleButton from "primevue/togglebutton";
import Skeleton from "primevue/skeleton";
import Breadcrumb from "primevue/breadcrumb";
import JsonEditorVue from "json-editor-vue";

// import {logger} from "@/utils/services/app-logger";
// import CodeHighlight from './AppCodeHighlight';
// import BlockViewer from './components/blockviewer/BlockViewer.vue';

export const app = createApp(App)
app.use(PrimeVue, {
    ripple: true,
    locale: {
        aria: {
            trueLabel: '',
            falseLabel: '',
            nullLabel: '',
            star: '',
            stars: '',
            selectAll: '',
            unselectAll: '',
            close: '',
            previous: '',
            next: '',
            navigation: '',
            scrollTop: '',
            moveUp: '',
            moveTop: '',
            moveDown: '',
            moveBottom: '',
            moveToTarget: '',
            moveToSource: '',
            moveAllToTarget: '',
            moveAllToSource: '',
            pageLabel: '',
            firstPageLabel: '',
            lastPageLabel: '',
            nextPageLabel: '',
            prevPageLabel: '',
            rowsPerPageLabel: '',
            jumpToPageDropdownLabel: '',
            jumpToPageInputLabel: '',
            selectRow: '',
            unselectRow: '',
            expandRow: '',
            collapseRow: '',
            showFilterMenu: '',
            hideFilterMenu: '',
            filterOperator: '',
            filterConstraint: '',
            editRow: '',
            saveEdit: '',
            cancelEdit: '',
            listView: '',
            gridView: '',
            slide: '',
            slideNumber: '',
            zoomImage: '',
            zoomIn: '',
            zoomOut: '',
            rotateRight: '',
            rotateLeft: '',
        },
        startsWith: 'Commence par',
        contains: 'Contient',
        notContains: 'Ne contient pas',
        endsWith: 'Termine par',
        equals: 'Égal à',
        notEquals: 'Différent de',
        noFilter: 'Pas de filtre',
        lt: 'Inférieur à',
        lte: 'Inférieur ou égal )',
        gt: 'Supérieur à',
        gte: 'Supérieur ou égal à',
        dateIs: 'La date est le',
        dateIsNot: 'La date n\'est pas',
        dateBefore: 'La date est avant le',
        dateAfter: 'La date est après le',
        clear: 'Vider',
        apply: 'Appliquer',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Oui',
        reject: 'Non',
        choose: 'Choix',
        upload: 'Téléverser',
        cancel: 'Annuler',
        dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
        monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
        today: 'Aujourd\'hui',
        weekHeader: 'Semaine',
        firstDayOfWeek: 1,
        dateFormat: 'dd/mm/yy',
        weak: 'Faible',
        medium: 'Moyen',
        strong: 'Fort',
        passwordPrompt: 'Entrez un mot de passe',
        emptyFilterMessage: 'Pas de résultat trouvé',
        emptyMessage: 'Aucune option disponible'
    }
});
app.use(ToastService);
app.use(router)
app.use(store)
const i18n = initI18n().i18n;
app.$i18n = i18n;
app.use(i18n);
app.use(SimpleVueValidator, {mode: 'manual'});

app.component('JsonEditorVue', JsonEditorVue);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AutoComplete', AutoComplete);
app.component('AvatarGroup', AvatarGroup);
app.component('Button', Button);
app.component('Breadcrumb', Breadcrumb);
app.component('Calendar', Calendar);
app.component('Password', Password);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chart', Chart);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('Card', Card);
app.component('Column', Column);
app.component('ColorPicker', ColorPicker);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('PickList', PickList);
app.component('Editor', Editor);
app.component('ToggleButton', ToggleButton);
app.component('FileUpload', FileUpload);
app.component('Menubar', Menubar);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('Toolbar', Toolbar);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Menu', Menu);
app.component('MultiSelect', MultiSelect);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Row', Row);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('Steps', Steps);
app.component('SplitButton', SplitButton);
app.component('Skeleton', Skeleton);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('Message', Message);
app.component('InlineMessage', InlineMessage);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('OverlayPanel', OverlayPanel);
app.use(ConfirmationService);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

// app.config.errorHandler = function (err, vm, info) {
//     logger.logToServer({ err, vm, info });
// }
//
// window.onerror = function(message, source, lineno, colno, error) {
//     logger.logToServer({ message, source, lineno, colno, error });
// };

app.mount('#app')
