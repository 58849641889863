export default [
    {
        path: '/',
        redirect: { name: 'map_view' }
    },
    {
        path: '/login',
        name: 'Login',
        meta: {title: 'Buildy BMS - Connexion'},
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Login')
    },
    {
        path: '/reset',
        name: 'Reset',
        meta: {title: 'Buildy BMS - Réinitialisation'},
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Reset')
    },
    {
        path: '/user-accept',
        name: 'AcceptInvite',
        meta: {title: 'Buildy BMS - Inscription'},
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/AcceptInvite')
    },
    {
        path: '/notifications',
        name: 'notifications_view',
        meta: {title: 'Buildy BMS - Notifications'},
        component: () => import('/src/views/Pages/Notifications')
    },
    {
        path: '/monitoring',
        children: [
            {
                path: 'map',
                name: 'map_view',
                meta: {title: 'Buildy BMS - Carte'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Map')
            },
            {
                path: 'alerts',
                name: 'alerts_view',
                meta: {title: 'Buildy BMS - Anomalies'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Alerts')
            },
            {
                path: 'graphs',
                name: 'graphs_view',
                meta: {title: 'Buildy BMS - Graphiques'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/GraphsV2')
            },
            {
                path: 'buildings',
                children: [
                    {
                        path: 'zones',
                        name: 'site_zones_view',
                        meta: {title: 'Buildy BMS - Visualisation des zones'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Zones')
                    },
                    {
                        path: 'equipments',
                        name: 'site_equipments_view',
                        meta: {title: 'Buildy BMS - Visualisation des équipements'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Equipments')
                    },
                    {
                        path: 'datapoints',
                        name: 'site_datapoints_view',
                        meta: {title: 'Buildy BMS - Visualisation des données'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Datapoints')
                    },
                    {
                        path: 'dashboards',
                        children: [
                            {
                                path: 'electricity',
                                name: 'electricity_dashboard_view',
                                meta: {title: 'Buildy BMS - Consommation électrique'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/ElectricityConsumption.vue')
                            },
                            {
                                path: 'thermal',
                                name: 'thermal_dashboard_view',
                                meta: {title: 'Buildy BMS - Consommation thermique'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/ThermalConsumption.vue')
                            },
                            {
                                path: 'water',
                                name: 'water_dashboard_view',
                                meta: {title: 'Buildy BMS - Consommation d\'eau'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/WaterConsumption.vue')
                            },
                            {
                                path: 'gas',
                                name: 'gas_dashboard_view',
                                meta: {title: 'Buildy BMS - Consommation de gaz'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/GasConsumption.vue')
                            },
                            {
                                path: 'hvac',
                                name: 'hvac_dashboard_view',
                                meta: {title: 'Buildy BMS - CVC'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/CVC.vue')
                            },
                            {
                                path: 'iaq',
                                name: 'iaq_dashboard_view',
                                meta: {title: 'Buildy BMS - QAI'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/QAI.vue')
                            },
                            {
                                path: 'light',
                                name: 'lighting_dashboard_view',
                                meta: {title: 'Buildy BMS - Eclairages'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Buildings/Dashboards/Lighting.vue')
                            },
                        ]
                    }
                ]
            },
            {
                path: 'tasks',
                name: 'project_tasks_view',
                meta: {title: 'Buildy BMS - Tâches'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Tasks')
            },
            {
                path: 'schedulers',
                children: [
                    {
                        path: 'events',
                        name: 'schedulers_list_view',
                        meta: {title: 'Buildy BMS - Liste des événements'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Schedulers/Events')
                    },
                    {
                        path: 'calendars',
                        name: 'schedulers_cal_view',
                        meta: {title: 'Buildy BMS - Calendriers'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Schedulers/Calendars')
                    },
                ]
            },
        ]
    },
    {
        path: '/management',
        children: [
            {
                path: 'buildings',
                children: [
                    {
                        path: 'customers',
                        name: 'customers_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des clients'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/Customers')
                    },
                    {
                        path: 'sites',
                        name: 'sites_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des sites'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/Sites')
                    },
                    {
                        path: 'zones',
                        name: 'site_zones_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des zones'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/Zones')
                    },
                    {
                        path: 'equipments',
                        name: 'site_equipments_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des équipements'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/Equipments')
                    },
                    {
                        path: 'meter-equipments',
                        name: 'meters_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des compteurs'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/MeterEquipments')
                    },
                    {
                        path: 'datapoints',
                        name: 'site_datapoints_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des données'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Buildings/Datapoints')
                    },
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: 'groups',
                        name: 'users_groups_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des groupes d\'utilisateurs'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Users/UsersGroups')
                    },
                    {
                        path: 'users',
                        name: 'users_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des utilisateurs'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Users/Users')
                    }
                ]
            },
            {
                path: 'infrastructures',
                children: [
                    {
                        path: 'gateways',
                        children: [
                            {
                                path: 'edge',
                                children: [
                                    {
                                        path: '',
                                        name: 'edge_gw_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des passerelles Edge'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/Edge/Gateways'),
                                    },
                                    {
                                        path: ':gateway_id',
                                        name: 'edge_data_providers_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des concentrateurs Edge'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/Edge/EndDevices')
                                    },
                                    {
                                        path: ':gateway_id/:bacnet_device_id',
                                        name: 'edge_data_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des points Edge'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/Edge/DataSources')
                                    },
                                ]
                            },
                            {
                                path: 'lora',
                                children: [
                                    {
                                        path: '',
                                        name: 'lora_gw_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des passerelles LoRa'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/LoRa/Gateways'),
                                    },
                                    {
                                        path: ':gateway_id',
                                        name: 'lora_data_providers_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des capteurs LoRa'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/LoRa/EndDevices')
                                    },
                                    {
                                        path: ':gateway_id/:bacnet_device_id',
                                        name: 'lora_data_mgmt_view',
                                        meta: {title: 'Buildy BMS - Gestion des points LoRa'},
                                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/LoRa/DataSources')
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'variables',
                        name: 'variables_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des variables'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Frameworks/VirtualData'),
                    },
                ]
            },
            {
                path: 'data-profiles',
                name: 'data_profiles_mgmt_view',
                meta: {title: 'Buildy BMS - Gestion des profils de données'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/DataProfiles')
            },
            {
                path: 'tags',
                name: 'tags_mgmt_view',
                meta: {title: 'Buildy BMS - Gestion des tags'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Tags')
            },
            {
                path: 'projects',
                children: [
                    {
                        path: '',
                        name: 'projects_mgmt_view',
                        meta: {title: 'Buildy BMS - Gestion des projets'},
                        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Projects/Projects')
                    },
                    {
                        path: ':project_id',
                        children: [
                            {
                                path: 'tasks',
                                name: 'project_tasks_mgmt_view',
                                meta: {title: 'Buildy BMS - Gestion des tâches'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Projects/Tasks')
                            },
                            {
                                path: 'products',
                                name: 'project_products_mgmt_view',
                                meta: {title: 'Buildy BMS - Gestion des produits'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Projects/Products')
                            },
                            {
                                path: 'services',
                                name: 'project_services_mgmt_view',
                                meta: {title: 'Buildy BMS - Gestion des services'},
                                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Projects/Services')
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Map'),
        hidden: true
    }
]
