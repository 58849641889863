import axios from "@/plugins/axios";
import slugify from "slugify";

export default {
  namespaced: true,
  state: {
    tags: []
  },
  getters: {
    getTags (state) {
      return state.tags
    },
  },
  mutations: {
    SET_TAGS(state, value) {
      state.tags = value
    },
  },
  actions: {
    // eslint-disable-next-line
    getTags({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getTags').then(res => {
          const sorted = res.data.data.sort((a, b) => a.label.localeCompare(b.label))
          commit('SET_TAGS', sorted)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getOneTagDetails(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getTag/' + id).then(res => {
          console.log(res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createTag(_, data) {
      return new Promise((resolve, reject) => {
        data.slug = slugify(data.label)
        axios.axiosApiInstance.post('/createTag', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    deleteTag(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteTag/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateTag(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateTag/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
