<template>
  <div class="flex relative lg:static" v-if="isLogged" @click="onDocumentClick">
    <Toast position="top-right" />
    <div class="layout-wrapper layout-static" :class="sideContent.isVisible ? 'wide' : ''">
      <AppTopbar @zoom="zoom" @unzoom="unzoom" @reset="reset" class="appTopBarClass" @toggleMenu="toggleSidebarMenu" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive" @menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick" @topbar-submenu-click="onTopbarSubmenuClick"
                 @topbar-item-click="onTopbarItemClick" @search-item-click="onSearchItemClick"></AppTopbar>
<!--      <router-view name="header" />-->
      <Sidebar :current-sidebar-width="currentSidebarWidth"></Sidebar>
      <div :style="{ 'margin-left': currentSidebarWidth + 'px' }" class="layout-content-wrapper fill-available" id="layout-content-wrapper"> <div id="layout-content" class="layout-content" :style="{padding: noPaddingLayout ? '0' : ''}"><router-view :key="$route.path" /></div></div>
    </div>
<!--    width: (((1 - (currentSidebarWidth / windowWidth)) * 100) + '%'),-->
  </div>
  <div v-else>
    <Toast position="top-center" />
    <router-view :key="$route.path"/>
  </div>
  <Dialog v-model:visible="loadingComputed" :show-header="false" class="loading-dialog">
    <ProgressSpinner />
  </Dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import AppTopbar from "@/layouts/App/AppTopbar";
import Sidebar from "@/layouts/App/Sidebar";
import axios from "@/plugins/axios";

export default {
  name: 'App',
  components: {Sidebar, AppTopbar},
  computed: {
    loadingComputed () {
      return this.dashboardLoading || this.loading
    },
    ...mapGetters({
      globalToast: 'misc/getToastMessage'
    }),
    ...mapState({
      isLogged: state => state.auth.isLoggedIn,
      sideContent: state => state.sidebar.sideContent,
      toastAccessRight: state => state.auth.toastAccessRight,
      loading: state => state.misc.loading,
      dashboardLoading: state => state.misc.dashboardLoading,
      noPaddingLayout: state => state.misc.noPaddingLayout
    }),
  },
  data () {
    return {
      menuMode: 'slim',
      theme: 'indigo',
      layoutColor: 'white',
      tempLayoutColor: 'white',
      lightMenu: true,
      staticMenuMobileActive: false,
      staticMenuDesktopInactive: false,
      menuClick: false,
      topbarMenuClick: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      menuActive: false,
      searchClick: false,
      windowWidth: document.documentElement.clientWidth,
      defaultSidebarWidth: 280,
      shrinkSidebarWidth: 0,
      currentSidebarWidth: 280,
      smplrSpaceLoaded: false,
      currentZoom: 100
    }
  },
  watch: {
    toastAccessRight () {
      if (this.toastAccessRight) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Vous n\'avez pas les droits pour accéder à cette page.', life: 5000})
      }
      this.$store.state.auth.toastAccessRight = false
    },
    globalToast (errors) {
      try {
        if (Array.isArray(errors)) {
          errors.forEach(error => {
            this.$toast.add({severity: 'error', summary: 'Erreur', detail: error.message, life: 5000})
          })
          this.$store.commit('misc/SET_TOAST_MESSAGE', null)
        }
      } catch (e) {
        console.log(e)
      }

    }
  },
  async created () {
    await this.$store.restored
    axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.auth.token}`
    window.addEventListener('storage', (e) => {
      if (e.key === 'access') {
        const data = JSON.parse(e.newValue)
        if (data.refreshToken) {
          this.$store.commit('auth/SET_TOKEN', data.accessToken)
          this.$store.commit('auth/SET_REFRESH', data.refreshToken)
          this.$store.commit('auth/SET_ACCESS_RIGHTS', data.accessRights)
          axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
        }
      }
    });
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    // if (!this.smplrSpaceLoaded) {
    //   let link = document.createElement('link')
    //   link.setAttribute('href', 'https://app.smplrspace.com/lib/smplr.css')
    //   link.setAttribute('rel', 'stylesheet')
    //   document.head.appendChild(link)
    //   await loadScript("https://app.smplrspace.com/lib/smplr.js")
    //   this.smplrSpaceLoaded = true
    // }
  },
  methods: {
    zoom () {
      const currentZoom = document.body.style.zoom
      if (currentZoom) {
        const value = parseInt(currentZoom)
        document.body.style.zoom = `${value + 10}%`
        this.currentZoom = (value + 10)/100
      } else {
        document.body.style.zoom = `110%`
        this.currentZoom = 1.1
      }
    },
    unzoom () {
      const currentZoom = document.body.style.zoom
      if (currentZoom) {
        const value = parseInt(currentZoom)
        document.body.style.zoom = `${value - 10}%`
        this.currentZoom = (value - 10)/100
      } else {
        document.body.style.zoom = `90%`
        this.currentZoom = 0.9
      }
    },
    reset () {
      document.body.style.zoom = "100%"
    },
    toggleSidebarMenu () {
      this.currentSidebarWidth = this.currentSidebarWidth === this.defaultSidebarWidth ? this.shrinkSidebarWidth : this.defaultSidebarWidth
    },
    onDocumentClick() {
      if (!this.topbarItemClick && !this.searchClick) {
        this.activeTopbarItem = null;
      }

      if (!this.topbarMenuClick) {
        this.topbarMenuActive = false;
      }

      this.searchClick = false;
      this.topbarItemClick = false;
      this.topbarMenuClick = false;
      this.menuClick = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;

      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;

      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;


      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onSearchItemClick() {
      this.searchClick = true;
    },
    onTopbarMenuClick() {
      this.topbarMenuClick = true;
      this.topbarMenuActive = true;
    },
    onTopbarSubmenuClick(event) {
      this.topbarMenuClick = true;

      if (!event.item.items) {
        this.topbarMenuActive = false;
      }
    },
    onTopbarMenuItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
  }
}
</script>

<style>

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*height: 100vh;*/
  /*margin-top: 60px;*/
}
.router-view {
  height: calc(100vh);
}
/*Pour la largeur des pop-up de filtre*/
.p-column-filter-overlay, .p-column-filter-overlay-menu {
  width: auto !important;
}

</style>
<style lang="scss" scoped>
#layout-content {
  background-color: var(--surface-ground);
  padding: 1rem;
}

.wide .layout-content-wrapper {
  margin-left: 20rem !important;
  width: calc(100% - 20rem) !important;
}
</style>
