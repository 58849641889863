export const kPrimaryColor = '#00CD92'
export const kSecondaryBlue= '#1B2842'

export const gDangerColor = '#FE4166'
export const gWarningColor = '#FFBF46'
export const gGoodColor = '#30DFA7'
export const gColdColor = '#1496FF'
export const gBlueColor = '#3EB7DF'
export const gPurpleColor = '#7A30DF'
export const gGreyColor = '#7B859A'
export const gMagentaColor = '#DA3EDF'

export const pSBC=(p,c0,c1,l)=>{
    let pSBCr
    let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    if(!pSBCr)pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
            [r,g,b,a]=d=d.split(","),n=d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
    h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
}

export const getHexColorByName = (name) => {
    switch (name) {
        case 'blue':
            return gColdColor;
        case 'red':
            return gDangerColor;
        case 'yellow':
            return gWarningColor;
        case 'green':
            return gGoodColor;
        case 'purple':
            return gPurpleColor
        case 'magenta':
            return gMagentaColor
        case 'grey':
            return gGreyColor;
        default:
            return null;
    }
}

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const getStyleByName = () => {
    return ''
    // switch (name) {
    //     case 'blue':
    //         return 'filter: invert(44%) sepia(88%) saturate(2026%) hue-rotate(186deg) brightness(99%) contrast(104%);'
    //     case 'red':
    //         return 'filter: invert(38%) sepia(68%) saturate(3205%) hue-rotate(325deg) brightness(101%) contrast(99%);'
    //     case 'yellow':
    //         return 'filter: invert(72%) sepia(66%) saturate(498%) hue-rotate(340deg) brightness(103%) contrast(104%);'
    //     case 'green':
    //         return 'filter: invert(86%) sepia(17%) saturate(1788%) hue-rotate(94deg) brightness(95%) contrast(84%);'
    //     case 'purple':
    //         return 'filter: invert(30%) sepia(66%) saturate(7424%) hue-rotate(260deg) brightness(89%) contrast(95%);'
    //     case 'magenta':
    //         return 'filter: invert(40%) sepia(32%) saturate(3406%) hue-rotate(271deg) brightness(88%) contrast(101%);'
    //     case 'grey':
    //         return 'filter: invert(55%) sepia(8%) saturate(765%) hue-rotate(183deg) brightness(92%) contrast(93%);'
    //     default:
    //         return 'filter: invert(55%) sepia(80%) saturate(1300%) hue-rotate(120deg) brightness(93%) contrast(107%);'
    // }
}


// "use strict";
//
// class Color {
//     constructor(r, g, b) { this.set(r, g, b); }
//     toString() { return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`; }
//
//     set(r, g, b) {
//         this.r = this.clamp(r);
//         this.g = this.clamp(g);
//         this.b = this.clamp(b);
//     }
//
//     hueRotate(angle = 0) {
//         angle = angle / 180 * Math.PI;
//         let sin = Math.sin(angle);
//         let cos = Math.cos(angle);
//
//         this.multiply([
//             0.213 + cos * 0.787 - sin * 0.213, 0.715 - cos * 0.715 - sin * 0.715, 0.072 - cos * 0.072 + sin * 0.928,
//             0.213 - cos * 0.213 + sin * 0.143, 0.715 + cos * 0.285 + sin * 0.140, 0.072 - cos * 0.072 - sin * 0.283,
//             0.213 - cos * 0.213 - sin * 0.787, 0.715 - cos * 0.715 + sin * 0.715, 0.072 + cos * 0.928 + sin * 0.072
//         ]);
//     }
//
//     grayscale(value = 1) {
//         this.multiply([
//             0.2126 + 0.7874 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 - 0.0722 * (1 - value),
//             0.2126 - 0.2126 * (1 - value), 0.7152 + 0.2848 * (1 - value), 0.0722 - 0.0722 * (1 - value),
//             0.2126 - 0.2126 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 + 0.9278 * (1 - value)
//         ]);
//     }
//
//     sepia(value = 1) {
//         this.multiply([
//             0.393 + 0.607 * (1 - value), 0.769 - 0.769 * (1 - value), 0.189 - 0.189 * (1 - value),
//             0.349 - 0.349 * (1 - value), 0.686 + 0.314 * (1 - value), 0.168 - 0.168 * (1 - value),
//             0.272 - 0.272 * (1 - value), 0.534 - 0.534 * (1 - value), 0.131 + 0.869 * (1 - value)
//         ]);
//     }
//
//     saturate(value = 1) {
//         this.multiply([
//             0.213 + 0.787 * value, 0.715 - 0.715 * value, 0.072 - 0.072 * value,
//             0.213 - 0.213 * value, 0.715 + 0.285 * value, 0.072 - 0.072 * value,
//             0.213 - 0.213 * value, 0.715 - 0.715 * value, 0.072 + 0.928 * value
//         ]);
//     }
//
//     multiply(matrix) {
//         let newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
//         let newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
//         let newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
//         this.r = newR; this.g = newG; this.b = newB;
//     }
//
//     brightness(value = 1) { this.linear(value); }
//     contrast(value = 1) { this.linear(value, -(0.5 * value) + 0.5); }
//
//     linear(slope = 1, intercept = 0) {
//         this.r = this.clamp(this.r * slope + intercept * 255);
//         this.g = this.clamp(this.g * slope + intercept * 255);
//         this.b = this.clamp(this.b * slope + intercept * 255);
//     }
//
//     invert(value = 1) {
//         this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255);
//         this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255);
//         this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255);
//     }
//
//     hsl() { // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
//         let r = this.r / 255;
//         let g = this.g / 255;
//         let b = this.b / 255;
//         let max = Math.max(r, g, b);
//         let min = Math.min(r, g, b);
//         let h, s, l = (max + min) / 2;
//
//         if(max === min) {
//             h = s = 0;
//         } else {
//             let d = max - min;
//             s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
//             switch(max) {
//                 case r: h = (g - b) / d + (g < b ? 6 : 0); break;
//                 case g: h = (b - r) / d + 2; break;
//                 case b: h = (r - g) / d + 4; break;
//             } h /= 6;
//         }
//
//         return {
//             h: h * 100,
//             s: s * 100,
//             l: l * 100
//         };
//     }
//
//     clamp(value) {
//         if(value > 255) { value = 255; }
//         else if(value < 0) { value = 0; }
//         return value;
//     }
// }

// class Solver {
//     constructor(target) {
//         this.target = target;
//         this.targetHSL = target.hsl();
//         this.reusedColor = new Color(0, 0, 0); // Object pool
//     }
//
//     solve() {
//         let result = this.solveNarrow(this.solveWide());
//         return {
//             values: result.values,
//             loss: result.loss,
//             filter: this.css(result.values)
//         };
//     }
//
//     solveWide() {
//         const A = 5;
//         const c = 15;
//         const a = [60, 180, 18000, 600, 1.2, 1.2];
//
//         let best = { loss: Infinity };
//         for(let i = 0; best.loss > 25 && i < 3; i++) {
//             let initial = [50, 20, 3750, 50, 100, 100];
//             let result = this.spsa(A, a, c, initial, 1000);
//             if(result.loss < best.loss) { best = result; }
//         } return best;
//     }
//
//     solveNarrow(wide) {
//         const A = wide.loss;
//         const c = 2;
//         const A1 = A + 1;
//         const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
//         return this.spsa(A, a, c, wide.values, 500);
//     }
//
//     spsa(A, a, c, values, iters) {
//         const alpha = 1;
//         const gamma = 0.16666666666666666;
//
//         let best = null;
//         let bestLoss = Infinity;
//         let deltas = new Array(6);
//         let highArgs = new Array(6);
//         let lowArgs = new Array(6);
//
//         for(let k = 0; k < iters; k++) {
//             let ck = c / Math.pow(k + 1, gamma);
//             for(let i = 0; i < 6; i++) {
//                 deltas[i] = Math.random() > 0.5 ? 1 : -1;
//                 highArgs[i] = values[i] + ck * deltas[i];
//                 lowArgs[i]  = values[i] - ck * deltas[i];
//             }
//
//             let lossDiff = this.loss(highArgs) - this.loss(lowArgs);
//             for(let i = 0; i < 6; i++) {
//                 let g = lossDiff / (2 * ck) * deltas[i];
//                 let ak = a[i] / Math.pow(A + k + 1, alpha);
//                 values[i] = fix(values[i] - ak * g, i);
//             }
//
//             let loss = this.loss(values);
//             if(loss < bestLoss) { best = values.slice(0); bestLoss = loss; }
//         } return { values: best, loss: bestLoss };
//
//         function fix(value, idx) {
//             let max = 100;
//             if(idx === 2 /* saturate */) { max = 7500; }
//             else if(idx === 4 /* brightness */ || idx === 5 /* contrast */) { max = 200; }
//
//             if(idx === 3 /* hue-rotate */) {
//                 if(value > max) { value = value % max; }
//                 else if(value < 0) { value = max + value % max; }
//             } else if(value < 0) { value = 0; }
//             else if(value > max) { value = max; }
//             return value;
//         }
//     }
//
//     loss(filters) { // Argument is array of percentages.
//         let color = this.reusedColor;
//         color.set(0, 0, 0);
//
//         color.invert(filters[0] / 100);
//         color.sepia(filters[1] / 100);
//         color.saturate(filters[2] / 100);
//         color.hueRotate(filters[3] * 3.6);
//         color.brightness(filters[4] / 100);
//         color.contrast(filters[5] / 100);
//
//         let colorHSL = color.hsl();
//         return Math.abs(color.r - this.target.r)
//             + Math.abs(color.g - this.target.g)
//             + Math.abs(color.b - this.target.b)
//             + Math.abs(colorHSL.h - this.targetHSL.h)
//             + Math.abs(colorHSL.s - this.targetHSL.s)
//             + Math.abs(colorHSL.l - this.targetHSL.l);
//     }
//
//     css(filters) {
//         function fmt(idx, multiplier = 1) { return Math.round(filters[idx] * multiplier); }
//         return `filter: invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%);`;
//     }
// }