/**
 * Gestion des items de la sidebar de gauche
 * props: [icon, name, label, to]
 * name: Permet de garder la séléction dans le state
 * label: Le titre a afficher
 * to: La route
 */
export default [
    {
        icon: 'pi pi-eye', name: 'monitoring', label: 'SUPERVISION', children: [
            {icon: 'pi pi-map-marker', name: 'map_view', label: 'Carte', to: 'map_view'},
            {icon: 'pi pi-bell', name: 'alerts_view', label: 'Anomalies', to: 'alerts_view'},
            {
                icon: 'pi pi-calendar', name: 'MonitoringSchedulers', label: 'Programmations', children: [
                    {icon: 'pi pi-calendar', name: 'schedulers_cal_view', label: 'Calendriers', to: 'schedulers_cal_view'},
                    {icon: 'pi pi-list', name: 'schedulers_list_view', label: 'Liste des événements', to: 'schedulers_list_view'},
                ]
            },
            {
                icon: 'pi pi-th-large', name: 'MonitoringBuildings', unfold: true, label: 'Bâtiments', children: [
                    {
                        icon: 'pi pi-chart-pie', name: 'MonitoringBuildingsDashboards', label: 'Tableaux de bord', children: [
                            {
                                icon: 'fa-regular fa-gauge-circle-bolt', label: 'Consommations', children: [
                                    {icon: 'fa-regular fa-droplet', name: 'water_dashboard_view', label: 'Eau', to: 'water_dashboard_view'},
                                    {icon: 'fa-regular fa-bolt', name: 'electricity_dashboard_view', label: 'Electricité', to: 'electricity_dashboard_view'},
                                    {icon: 'fa-regular fa-fire-flame', name: 'gas_dashboard_view', label: 'Gaz', to: 'gas_dashboard_view'},
                                    {icon: 'fa-regular fa-temperature-three-quarters', name: 'thermal_dashboard_view', label: 'Calories', to: 'thermal_dashboard_view'},
                                ]
                            },
                            {icon: 'fa-regular fa-lightbulb-on', name: 'lighting_dashboard_view', label: 'Eclairage', to: 'lighting_dashboard_view'},
                            {icon: 'fa-regular fa-air-conditioner', name: 'hvac_dashboard_view', label: 'CVC', to: 'hvac_dashboard_view'},
                            {icon: 'fa-regular fa-circle-radiation', name: 'iaq_dashboard_view', label: 'Qualité de l\'air', to: 'iaq_dashboard_view'},
                            // {icon: 'pi pi-chart-pie', name: 'alarms_dashboard_view', label: 'Anomalies', to: 'alarms_dashboard_view'},
                        ]
                    },
                    {icon: 'pi pi-sitemap', name: 'site_zones_view', label: 'Zones', to: 'site_zones_view'},
                    {icon: 'pi pi-th-large', name: 'site_equipments_view', label: 'Équipements', to: 'site_equipments_view'},
                    {icon: 'pi pi-list', name: 'site_datapoints_view', label: 'Données', to: 'site_datapoints_view'},
                ]
            },
            {icon: 'pi pi-chart-bar', name: 'graphs_view', label: 'Graphiques', to: 'graphs_view'},
            {icon: 'pi pi-check-circle', name: 'project_tasks_view', label: 'Tâches', to: 'project_tasks_view'},
        ]
    },
    {
        icon: 'pi pi-wrench', name: 'Management', hidden: true, label: 'GESTION', children: [
            {icon: 'pi pi-id-card', name: 'customers_mgmt_view', label: 'Clients', to: 'customers_mgmt_view'},
            {
                icon: 'pi pi-th-large', name: 'ManagementBuildings', label: 'Bâtiments', children: [
                    {icon: 'pi pi-building', name: 'sites_mgmt_view', label: 'Sites', to: 'sites_mgmt_view'},
                    {icon: 'pi pi-list', name: 'projects_mgmt_view', label: 'Projets', to: 'projects_mgmt_view'},
                    {icon: 'pi pi-sitemap', name: 'site_zones_mgmt_view', label: 'Zones', to: 'site_zones_mgmt_view'},
                    {icon: 'pi pi-th-large', name: 'site_equipments_mgmt_view', label: 'Équipements', to: 'site_equipments_mgmt_view'},
                    {icon: 'pi pi-chart-pie', name: 'meters_mgmt_view', label: 'Compteurs', to: 'meters_mgmt_view'},
                    {icon: 'pi pi-list', name: 'site_datapoints_mgmt_view', label: 'Données', to: 'site_datapoints_mgmt_view'},
                ]
            },
            {
                icon: 'pi pi-users', name: 'ManagementUsers', label: 'Utilisateurs', children: [
                    {icon: 'pi pi-users', name: 'users_groups_mgmt_view', label: 'Groupe d\'utilisateurs', to: 'users_groups_mgmt_view'},
                    {icon: 'pi pi-user', name: 'users_mgmt_view', label: 'Utilisateurs', to: 'users_mgmt_view'},
                ]
            },
            {
                icon: 'pi pi-share-alt', name: 'ManagementFrameworks', label: 'Infrastructures', children: [
                    {icon: 'pi pi-server', name: 'edge_gw_mgmt_view', label: 'Passerelles Edge', to: 'edge_gw_mgmt_view'},
                    {icon: 'pi pi-server', name: 'lora_gw_mgmt_view', label: 'Passerelles LoRa', to: 'lora_gw_mgmt_view'},
                    {icon: 'pi pi-server', name: 'variables_mgmt_view', label: 'Variables', to: 'variables_mgmt_view'},
                ]
            },
            {icon: 'pi pi-cog', name: 'data_profiles_mgmt_view', label: 'Profils de données', to: 'data_profiles_mgmt_view'},
            {icon: 'pi pi-tags', name: 'tags_mgmt_view', label: 'Tags', to: 'tags_mgmt_view'},
        ]
    },
]
