import {createStore} from 'vuex-extensions'
import VuexPersistence from "vuex-persist";
import { Store } from "vuex"

// MODULES
import sidebar from './modules/sidebar'
import misc from './modules/misc'
import auth from './modules/auth'
import sites from './modules/sites'
import gateways from './modules/gateways'
import bacnet_devices from './modules/bacnet_devices'
import data_sources from './modules/data_sources'
import type_datapoint from './modules/type_datapoint'
import alerts from './modules/alerts'
import zones from './modules/zones'
import datapoints from './modules/datapoints'
import schedulers from './modules/schedulers'
import equipments from './modules/equipments'
import graph from './modules/graph'
import mqtt from './modules/mqtt'
import graph_presets from './modules/graph_presets'
import user_groups from './modules/user_groups'
import user_profiles from './modules/user_profiles'
import tags from './modules/tags'
import dashboard from './modules/dashboard'
import projects from './modules/projects'
import users from './modules/users'
import localforage from "localforage";
import customers from "@/store/modules/customers";
import bms from "@/store/modules/bms";
import subscriptions from "@/store/modules/subscriptions";
import camera_placements from "@/store/modules/camera_placements";

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: async (key) => {
      const raw = await localforage.getItem(key)
      return raw ? JSON.parse(raw) : {}
    },
    setItem: async (key, value) => {
      const valueString = JSON.stringify(value)
      await localforage.setItem(key, valueString)
    },
    removeItem: async (key) => await localforage.removeItem(key),
  },
  asyncStorage: true
})

export const store = createStore(Store,{
  plugins: [vuexLocal.plugin],
  modules: {
    sidebar,
    customers,
    misc,
    bms,
    auth,
    sites,
    gateways,
    subscriptions,
    bacnet_devices,
    data_sources,
    type_datapoint,
    alerts,
    zones,
    datapoints,
    equipments,
    graph,
    dashboard,
    mqtt,
    graph_presets,
    projects,
    schedulers,
    user_groups,
    user_profiles,
    tags,
    users,
    camera_placements
  },
})


