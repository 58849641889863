import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    me: null,
    isLoggedIn: false,
    token: null,
    refresh: null,
    access_rights: {},
    toastAccessRight: false
  },
  mutations: {
    SET_LOGGED_IN(state, val) {
      state.isLoggedIn = val
    },
    SET_TOKEN(state, val) {
      state.token = val
    },
    SET_REFRESH(state, val) {
      state.refresh = val
    },
    SET_ME(state, val) {
      state.me = val
    },
    SET_ACCESS_RIGHTS(state, val) {
      state.access_rights = val
    }
  },
  getters: {
    getAccessRights(state) {
      return state.access_rights
    }
  },
  actions: {
    async login({ commit }, data) {
      return new Promise((resolve, reject) => {
        const rememberMe = data.remember
        delete data.remember
        axios.axiosApiInstance.post('/login', data).then(async (res) => {
          commit('SET_ACCESS_RIGHTS', res.data.data.user.profile.access_rights?.buildy_bms_app)
          commit('SET_LOGGED_IN', true)
          commit('SET_TOKEN', res.data.data.login.access_token)
          if (rememberMe) {
            commit('SET_REFRESH', res.data.data.login.refresh_token)
            localStorage.setItem('access', JSON.stringify({refreshToken: res.data.data.login.refresh_token, accessToken: res.data.data.login.access_token, accessRights: res.data.data.user.profile.access_rights?.buildy_bms_app}))
          }
          commit('SET_ME', res.data.data.user)
          resolve(res);
        }).catch((e) => {
          commit('SET_LOGGED_IN', false)
          reject(e);
        })
      });
    },
    async refresh({ commit }) {
      const access = window.localStorage.getItem('access') ?? '{}'
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/refresh', { refresh: JSON.parse(access).refreshToken })
          .then((res) => {
            localStorage.setItem('access', JSON.stringify({refreshToken: res.data.login.data.refresh_token, accessToken: res.data.login.data.access_token, accessRights: res.data.access_rights?.buildy_bms_app}))
            commit('SET_TOKEN', res.data.login.data.access_token)
            commit('SET_REFRESH', res.data.login.data.refresh_token)
            commit('SET_ACCESS_RIGHTS', res.data.access_rights?.buildy_bms_app)
            axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.login.data.access_token}`
            resolve(res.data.login.data.access_token)
          })
          .catch((e) => {
            reject(e);
          })
      })
    },
    async requestResetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/requestReset', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async resetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/reset', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async acceptInvite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/acceptInvite', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async logout({ commit }) {
      commit('SET_LOGGED_IN', false)
      commit('SET_TOKEN', null)
      commit('SET_REFRESH', null)
      commit('SET_ME', null)
      commit('SET_ACCESS_RIGHTS', null)
    },
    async isAuthenticated({ state, commit }) {
      if (!state.token) throw 'not authenticated'
      axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${state.token}`
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/connected').then(res => {
          commit('SET_ACCESS_RIGHTS', res.data.data.access_rights?.buildy_bms_app)
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
}
