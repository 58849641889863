import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    projects: [],
    statuses: [],
    locations: [],
    categories: [],
    projectTypes: [],
    itemDocsTypes: [],
  },
  getters: {
    getProjects (state) {
      return state.projects
    },
    getStatuses (state) {
      return state.statuses
    },
    getLocations (state) {
      return state.locations
    },
    getCategories (state) {
      return state.categories
    },
    getProjectTypes (state) {
      return state.projectTypes
    },
    getItemDocsTypes (state) {
      return state.itemDocsTypes
    },
  },
  mutations: {
    SET_PROJECTS(state, value) {
      state.projects = value
    },
    SET_STATUSES(state, value) {
      state.statuses = value
    },
    SET_LOCATIONS(state, value) {
      state.locations = value
    },
    SET_CATEGORIES(state, value) {
      state.categories = value
    },
    SET_PROJECT_TYPES(state, value) {
      state.projectTypes = value
    },
    SET_ITEM_DOCS_TYPES(state, value) {
      state.itemDocsTypes = value
    },
  },
  actions: {
    // eslint-disable-next-line
    getProjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getProjects').then(res => {
          const sorted = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          commit('SET_PROJECTS', sorted)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProject(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProject/${id}`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectItems(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProject/${id}/getItems`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectsItems() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProjectsItems`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectServices(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProject/${id}/getServices`).then(res => {
          resolve(res.data.data.map(d => ({...d, end_date: d.end_date ? new Date(d.end_date) : ''})).sort((a, b) => a.end_date && !b.end_date ? -1 : !a.end_date && b.end_date ? 1 : a.end_date < b.end_date ? -1 : 1))
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectsServices() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProjectsServices`).then(res => {
          resolve(res.data.data.map(d => ({...d, end_date: d.end_date ? new Date(d.end_date) : ''})).sort((a, b) => a.end_date && !b.end_date ? -1 : !a.end_date && b.end_date ? 1 : a.end_date < b.end_date ? -1 : 1))
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectTasks(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProject/${id}/getTasks`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProjectsTasks() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getProjectsTasks`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getStatuses({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getStatuses').then(res => {
          const sorted = res.data.data.sort((a, b) => a.order - b.order)
          commit('SET_STATUSES', sorted)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getSuppliers() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getSuppliers').then(res => {
          const sorted = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getCrmDocs() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getCrmDocs').then(res => {
          const sorted = res.data.data.sort((a, b) => a.sellsy_number.localeCompare(b.sellsy_number))
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getItems() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getItems').then(res => {
          const sorted = res.data.data.sort((a, b) => a.order > b.order ? -1 : 1)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getServices() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getServices').then(res => {
          const sorted = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createProject(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createProject', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    cloneProject(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/cloneProject', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createCrmDoc(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createCrmDoc', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createProjectTask(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createProjectTask', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createProjectItems(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createProjectItems', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createProjectService(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createProjectService', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateProject(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateProject/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateCrmDoc(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateCrmDoc/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async searchSellsyOrder(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/searchSellsyOrder', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateProjectTask(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateProjectTask/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteProjectTask(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteProjectTask/' + data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteProjectItem(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteProjectItem/' + data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteProjectService(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteProjectService/' + data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateProjectItem(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateProjectItem/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateProjectService(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateProjectService/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getProjectItemLocations({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getProjectItemsLocations').then(res => {
          const types = res.data.sort((a, b) => a.text.localeCompare(b.text))
          commit('SET_LOCATIONS', types)
          resolve(types)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getServicesCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getServicesCategories').then(res => {
          const types = res.data.sort((a, b) => a.text.localeCompare(b.text))
          commit('SET_CATEGORIES', types)
          resolve(types)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getProjectTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getProjectTypes').then(res => {
          const types = res.data.sort((a, b) => a.text.localeCompare(b.text))
          commit('SET_PROJECT_TYPES', types)
          resolve(types)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getItemDocsTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getItemDocsTypes').then(res => {
          // const types = res.data.sort((a, b) => a.text.localeCompare(b.text))
          commit('SET_ITEM_DOCS_TYPES', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
