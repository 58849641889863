import {store} from "@/store";
import router from "@/router";

const axios = require('axios');
const axiosApiInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

axiosApiInstance.interceptors.request.use((config) => {
    if (config.url !== '/refresh') {
        const access = window.localStorage.getItem('access')
        if (access) {
            const token = JSON.parse(access).accessToken
            config.headers['Authorization'] = `Bearer ${token}`
        }
    }
    return config
})

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/refresh') {
        originalRequest._retry = true;
        const token = await store.dispatch('auth/refresh')
        originalRequest.headers['Authorization'] = `Bearer ${token}`
        return axiosApiInstance(originalRequest);
    } else if (originalRequest.url === '/refresh') {
        await store.dispatch('auth/logout')
        router.push({ name: 'Login'})
    } else {
        try {
            store.state.misc.loading = false
            store.state.misc.globalLoading = false
            if (originalRequest.error_message) {
                if (!originalRequest.error_message_code || originalRequest.error_message_code === error.response.status) {
                    store.commit('misc/SET_TOAST_MESSAGE', [{message: originalRequest.error_message}])
                }
            } else {
                if (error.response.status === 403) {
                    store.commit('misc/SET_TOAST_MESSAGE', [{message: `Vous n'avez pas le droit de faire cela !`}])
                } else {
                    if (error.response?.data?.data?.errors) store.commit('misc/SET_TOAST_MESSAGE', error.response?.data?.data?.errors)
                    else store.commit('misc/SET_TOAST_MESSAGE', [{message: 'Une erreur s\'est produite'}])
                }
            }
        } catch (e) {
            store.state.misc.loading = false
            store.state.misc.globalLoading = false
            console.log(e)
            store.commit('misc/SET_TOAST_MESSAGE', [{message: 'Une erreur s\'est produite'}])
        }
    }
    return Promise.reject(error);
});

export default {
    axiosApiInstance
};
