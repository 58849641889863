import axios from "@/plugins/axios";
import {store} from "@/store";

export default {
  namespaced: true,

  state: {
    sites: [],
    customers: [],
    site: null
  },
  mutations: {
    SET_SITES(state, value) {
      state.sites = value
    },
    SET_CUSTOMERS(state, value) {
      state.customers = value
    },
    SET_SITE(state, value) {
      state.site = value
    }
  },
  getters: {
    getSites(state) {
      return state.sites
    },
    getCustomers(state) {
      return state.customers
    },
    getSite(state) {
      return state.site
    }
  },
  actions: {
    // eslint-disable-next-line
    async updateSiteDataCount(_, site_id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/updateSiteDataCount/' + site_id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async searchAddress(_, query) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/address/search/' + query).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllSites({ commit }) {
      if (!axios.axiosApiInstance.defaults.headers.Authorization) {
        axios.axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token}`
      }
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getSites/bms2`).then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          sortedArray.map(site => ({...site, zones_id: site.zones_id.sort((a, b) => a.name.localeCompare(b.name))}))
          commit('SET_SITES', sortedArray)
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllActiveSites() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getSites/bms2/active`).then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          sortedArray.map(site => ({...site, zones_id: site.zones_id.sort((a, b) => a.name.localeCompare(b.name))}))
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllSitesWithOpportunities() {
      if (!axios.axiosApiInstance.defaults.headers.Authorization) {
        axios.axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token}`
      }
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/getSites/bms2?opportunities=true`).then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          sortedArray.map(site => ({...site, zones_id: site.zones_id.sort((a, b) => a.name.localeCompare(b.name))}))
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllSitesDetails() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getSites/details').then(res => {
          const sortedArray = res.data.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.customer_id?.name?.localeCompare(b.customer_id?.name))
          // sortedArray.forEach(site => {
          //   let equipments_count = 0
          //   let datapoints_count = 0
          //   site.zones_id.forEach(zone => {
          //     zone.equipments_id.forEach(equipment => {
          //       datapoints_count += equipment.datapoints_id.length
          //     })
          //     equipments_count += zone.equipments_id.length
          //   })
          //   site.equipments_count = equipments_count
          //   site.datapoints_count = datapoints_count
          //   site.zones_count = site.zones_id.length
          // })
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getOneSite({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getSites/' + uuid).then(res => {
          res.data.data[0].zones_id.forEach(zone => {
            zone.timeout /= 60
          })
          commit('SET_SITE', res.data.data[0])
          resolve(res.data.data[0])
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateSite/' + data.site_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createSite', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async duplicateSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/duplicateSite', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getQrcode (_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/createpdf/' + data.site_uuid).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
}
