import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    camera_placements: [],
  },
  mutations: {
    SET_CAMERA_PLACEMENTS(state, value) {
      state.camera_placements = value
    },
  },
  getters: {
    getCameraPlacements(state) {
      return state.camera_placements.sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  actions: {
    // eslint-disable-next-line
    async getAllCameraPlacementsBySite({ commit }, site_id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getCameraPlacementsBySite/' + site_id).then(res => {
          commit('SET_CAMERA_PLACEMENTS', res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createCameraPlacement(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createCameraPlacement', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateCameraPlacement(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateCameraPlacement/' + data.id, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteCameraPlacement(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteCameraPlacement/' + id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
